<template>
    <v-container
            fill-height
            fluid
            grid-list-xl
    >
        <v-layout
                justify-center
                wrap
        >
            <data-table ref="orgDataTable"
                        :key="'ManageOrgs'"
                        :api-end-point="apiurl"
                        :search="search"
                        :is-custom-filter="false"
                        :sort-order="sortOrder"
                        :table-name="'Manage Orgs'"
                        :user_id="user_id"
                        :user_type="user_type"
                        :isDetail="true"
            >
                <template slot="datatable-actions">
                    <div class="action-buttons ml-3">
                        <button @click="showOrganisationDialog" class="btn btn-primary">Add Organisation</button>
                    </div>
                </template>

            </data-table>
        </v-layout>

        <add-organisation v-if="addOrganisationDialog"
                          :org="org"
                          :visible="addOrganisationDialog"
                          v-on:hideAddOrgModal="addOrganisationDialog = false"
                          :action-type="actionType"
        ></add-organisation>

    </v-container>
</template>

<script>
    import DataTable from "../../components/common/dataTable/DataTable";
    import AddOrganisation from "../../components/main/orgConsole/manageOrgs/AddOrganisation";
    export default {
        name: "OrgConsole",
        components: {
            DataTable,
            AddOrganisation
        },
        props: {
            user_id: {
                user_id: [Number, String]
            },
            user_type: {
                type: String
            },
        },
        data() {
            return {
                apiurl: 'orgs',
                search: {
                    placeholder: 'Search',
                    searchAble: false
                },
                sortOrder: [
                    {
                        field: 'id',
                        sortField: 'id',
                        direction: 'desc'
                    }
                ],
                filters: [],
                selected_filters: [],
                addOrganisationDialog: false,
                org: null,
                actionType: "",
            }
        },
        events: {
          "open-details": function(data) {
            this.org = data;
            this.org.emails = this.org.emails ? this.org.emails.toString().split(',') : [];
            this.actionType = "edit";
            this.addOrganisationDialog = true;

          },
        },
        methods:{
          showOrganisationDialog(){
            this.addOrganisationDialog = true;
            this.org = {};
            this.org.emails = [];
            this.actionType = "add";
          }
      }
    }
</script>
